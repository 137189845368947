<template>
    <div class="login">
        <!-- logo -->
        <div class="logo"></div>
        <!-- 账号密码 -->
        <div class="login-main">
            <van-field class="van-input" style="margin-bottom: 0.4rem;" v-model="parmes.mobile" placeholder="请输入手机号" />

            <van-field
                class="van-input" 
                v-model="parmes.verifyCode"
                center
                clearable
                placeholder="请输入短信验证码"
            >
                <button :class="{'code-btn':true, 'code-btn-disabled':isSend, 'button—color': true}" slot="button" id='code' @click="getVerifyCode">获取验证码</button>
            </van-field>
            
            <div class="login-other" @click="accountLogin">使用账号登录</div>
        </div>
        <div class="login-btn">
            <button @click="toLogin">登录</button>
            <button class="btn2" @click="toRegister">注册账号</button>
        </div>
    </div>
</template>
<script>
import { Field, Toast } from 'vant';
import { mapGetters, mapActions } from 'vuex';
import LoginApi from '@/api/login/loginApi';
export default {
    components: {
        [Field.name]: Field 
    },
    data () {
        return {
            parmes: {
                projectId: '',           //项目id
                ddMemberId: '',          //丁丁医生会员的id
                mobile: '',                // 用户手机号
                verifyCode: ''             // 登录验证码
            },
            isSend: false,                  // 是否发送验证码
            codeTimer: null,                // 定时器
            isLogin: false
        }
    },
    created() {
        const {projectId,ddMemberId} = this.$route.query;
        this.parmes.projectId = projectId || '';
        this.parmes.ddMemberId = ddMemberId || '';
    },
    computed:{
        ...mapGetters({ 
            targetPath: 'targetPath',
        })
    },
    methods: {
        // 获取验证 
        getVerifyCode() {
            // console.log('getVerifyCode')
            const { mobile } = this.parmes
            if (this.isSend) return false
            if (!mobile) {
                Toast('请填写手机号');
                return;
            }else if(!/^1[0-9]{10}$/.test(mobile)) {
                Toast('请填写正确手机号码');
                return;
            }
            this.isSend = true;

            LoginApi.loginSms({ mobile }).then(res => {
                Toast('获取验证码成功');
                setTimeout(() => {
                    this.isSend = true
                    let ss = 60
                    this.codeTimer = setInterval(() => { // 倒计时
                        if (ss <= 1) {
                            // console.log('code',this.$refs.code)
                            document.getElementById('code').innerHTML = '重新获取'
                            this.isSend = false;
                            clearInterval(this.codeTimer)
                            return false
                        } else {
                            ss--
                            document.getElementById('code').innerHTML = ss + 's 后重新获取'
                        }
                    }, 1000)
                }, 1000)
            }).catch(err => {
                this.isSend = false;
                // console.log(err)
                Toast(MediaStreamError);
            })
        },
        // 登录
        toLogin() {
            const { path } = this.$route.query

            const obj = {
                mobile: '手机号',
                verifyCode: '验证码'
            }
            if(this.isLogin) return false;
            for(const i in obj) {
                if(!this.parmes[i]) {
                    Toast(`请填写${obj[i]}`);
                    return
                }
                if (i === 'mobile') {
                    if (!/^1[0-9]{10}$/.test(this.parmes[i])) {
                        Toast('请填写正确手机号码');
                        return
                    }
                }
            }
            this.isLogin = true;
            LoginApi.login({ ...this.parmes }).then(res => {

                // 清楚计数器
                clearInterval(this.codeTimer)
                localStorage.setItem('healthme_token', res.data.token)
                this.$store.commit('SET_TOKEN', res.data.token);
                window.location.href = this.targetPath.path
                this.$router.replace({
                    path: path || '/',
                    query: {id: this.parmes.projectId || '', ddMemberId: this.parmes.ddMemberId || '' }
                })
            }).catch(err => {
                this.isLogin = false;
                Toast(err);
            })
        },
        // 进入注册页面
        toRegister() {
            const { doctorId } = this.parmes
            this.$router.push({
                path: '/register',
                query: {...this.$route.query}
            })
        },
        // 账号登录
        accountLogin() {
            this.$router.push({
                path: '/numberLogon',
                query: {...this.$route.query}
            })
        }
    },
    destroyed(){
        clearInterval(this.codeTimer)
    }
}
</script>
<style lang="less">
.login {
    padding: 1.733333rem .4rem 0 .4rem;
    .logo {
       width: 2rem;
       height: 2rem; 
       margin: 0 auto;
       background: url('../../assets/images/logo.png') no-repeat;
       background-size: 100% 100%;
    }
    .login-main {
        margin-top: 1.266667rem;  
        .code-btn {
            width: 3.253333rem;
            height: 1.2rem;
            font-size: 15px;
            color: #fff;
            line-height: 1;
            text-align: center;
            background: #009DF3;
            border: none;
            
        }
        .code-btn-disabled {
            font-size: .32rem;
            background: none;
            border: 1px solid #DEDEDE;
            color: #bbb;
        }
        .login-other{
            width: 100%;
            font-size: 14px;
            color: #000000;
            text-align: right;
            margin-top: .32rem;
            line-height: 1.5;
        }
    }
    .login-btn {
        margin-top: 1.066667rem;
        button {
            width: 100%;
            height: 1.2rem;
            font-size: 18px;
            color: #fff;
            margin-bottom: .533333rem;    
            background: #009DF3;
            border-radius: .133333rem;
            border: none;
        }
        .btn2 {
            background: none;
            border: 1px solid #009DF3;
            color: #009DF3;
        }
    }
    .van-input {
        width: 100%;
        height: 1.2rem;
        font-size: 15px;
        color: #272727;
        padding:  0 0 0 .266667rem ;
        background: #F7F7F7;
        line-height: 1.2rem;
        &::after {
            border: none;
        }

    }
}
</style>

